/**
 * 判断移动端
 */
export const isMobile = () => {
  return window.navigator.userAgent.toLowerCase().match('mobile');
};

/**
 * 判断微信浏览器
 */
export const isWechat = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes("micromessenger");
};

/**
 * 判断浏览器是否为头部APP
 */
export const isBlackApp = () => {
  const ua = navigator.userAgent.toLowerCase();
  return (
    /micromessenger/i.test(ua) ||
    ua.includes("weibo") ||
    ua.includes("qq") ||
    ua.includes("mqqbrowser")
  );
};

/**
 * 判断平台
 */
export const isAndroid = () => {
  return navigator.userAgent.match('Android');
};

const langArr = ['en', 'id', 'ja', 'ko', 'th', 'vi', 'zh'];

export const getLanguageFromUA = () => {
  const languageMatch = window.navigator.userAgent.match(/language\/([^/]+)/);
  const lang_region = languageMatch ? languageMatch[1] : "en_US";
  const lang = lang_region.split('_')[0].split('-')[0];


  if (lang_region === 'zh_HK' || lang_region === 'zh_TW' || lang_region.toLocaleLowerCase().includes("zh-hant")) {
    return {
      lang: 'hk'
    };
  } else {
    return {
      lang: langArr.includes(lang) ? lang : 'en'
    }
  }
}