import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getLanguageFromUA } from "../service/util";

interface IProps {}

const Doc: React.FC<IProps> = (props) => {
  const {} = props;
  const { type } = useParams();
  const [params] = useSearchParams();
  const appName = params.get("appName") || "AiLady";
  const [component, setComponent] = useState<any>(appName);
  const [isLoading, setIsLoading] = useState(true);
  const loadComponent = async () => {
    // 动态导入需要加载的组件
    const dynamicComponent = await import(
      `../components/doc/${getLanguageFromUA().lang}/${type}`
    );
    // 更新组件状态，将动态导入的组件设置为当前组件
    setComponent(dynamicComponent.default);
    setIsLoading(false);
  };
  useEffect(() => {
    loadComponent().then((r) => {});
  }, []);
  return <div className="p-10">{!isLoading && component}</div>;
};

export default Doc;
